<script setup>
import { reactive, ref} from 'vue';
import axios from 'axios';

const jobId = () => {
  const params = new URLSearchParams(window.location.search);

  const paramsObj = Array.from(params.keys()).reduce(
      (acc, val) => ({ ...acc, [val]: params.get(val) }),
      {}
  );

  return paramsObj.job_id;
};

const jobData = await axios.get(`https://fourplex.marlon-schirl.me/jobs/${jobId()}`).catch(err => {
  window.location.href = '/404';
});

const translation = {
  'full-time': 'Vollzeit',
  'part-time': 'Teilzeit',
  'full-or-part-time': 'Voll-/Teilzeit',
  'permanent': 'Festanstellung',
  'intern': 'Praktikum',
  'trainee': 'Trainee Stelle',
  'freelance': 'Freelance Position',
};

const currentStep = ref(1);

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  availability: '',
  salary: '',
  resume: null,
  otherDocuments: [],
  privacyAccepted: false,
});

const handleFileUpload = (event, type) => {
  const files = event.target.files;

  if (type === 'resume') {
    form.resume = files[0];
  } else if (type === 'otherDocuments') {
    form.otherDocuments = [...form.otherDocuments, ...Array.from(files)];
  }
};

const validateCurrentStep = () => {
  if (currentStep.value === 1) {
    return form.firstName && form.lastName && form.email;
  }
  if (currentStep.value === 2) {
    return form.availability && form.salary;
  }
  if (currentStep.value === 3) {
    return form.resume && form.privacyAccepted;
  }
  return true;
};

const nextStep = () => {
  if (validateCurrentStep()) {
    if (currentStep.value < 3) currentStep.value++;
  } else {
    alert('Bitte alle erforderlichen Felder ausfüllen.');
  }
};

const previousStep = () => {
  if (currentStep.value > 1) currentStep.value--;
};

const submitForm = async () => {
  if (!validateCurrentStep()) {
    alert('Bitte alle erforderlichen Felder ausfüllen.');
    return;
  }

  const formData = new FormData();
  formData.append('job_position_id', jobId());
  formData.append('first_name', form.firstName);
  formData.append('last_name', form.lastName);
  formData.append('email', form.email);
  formData.append('phone', form.phone);
  formData.append('available_after', form.availability);
  formData.append('expectation', form.salary);

  if (form.resume) {
    formData.append('cv', form.resume);
  }

  form.otherDocuments.forEach((file, index) => {
    formData.append(`other[${index}]`, file);
  });

  formData.append('privacyAccepted', form.privacyAccepted);

  try {
    const response = await axios.post('https://fourplex.marlon-schirl.me/apply', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
 
    alert('Bewerbung erfolgreich abgeschickt!');

    setTimeout(() => {
      window.location.href = '/';
    }, 1500);
  } catch (error) {

    alert('Fehler beim Abschicken der Bewerbung.');

    setTimeout(() => {
      window.location.href = '/kontakt';
    }, 1500);
  }
};

const bytesToMB = (bytes) => (bytes / 1048576).toFixed(2);
</script>

<template>
  <div class="application-form width-40 flex flex-direction-column justify-content-center align-items-center gap-75">
    <div class="flex flex-direction-column gap-16">
      <h3 class="font-medium weight-bold uppercase">{{ jobData.data.name }}</h3>

      <span class="font-normal color-accent">{{ translation[jobData.data.employmentType] }}, {{ translation[jobData.data.schedule] }} · {{ jobData.data.office }} · M/W/D</span>
    </div>

    <form @submit.prevent="submitForm" class="full-width flex flex-direction-column justify-content-center align-items-center gap-50">

      <!-- Step 1: Personal Information -->
      <div v-if="currentStep === 1" class="full-width flex flex-direction-column gap-25">
        <h2 class="font-normal weight-bold uppercase accent">Persönliche Informationen</h2>

        <div class="group flex flex-direction-column-mobile gap-25">
          <div class="full-width flex flex-direction-column gap">
            <label for="firstName">Vorname *</label>
            <input v-model="form.firstName" type="text" id="firstName" required />
          </div>
          <div class="full-width flex flex-direction-column gap">
            <label for="lastName">Nachname *</label>
            <input v-model="form.lastName" type="text" id="lastName" required />
          </div>
        </div>

        <div class="group flex">
          <div class="full-width flex flex-direction-column gap">
            <label for="email">E-Mail *</label>
            <input v-model="form.email" type="email" id="email" required />
          </div>
        </div>

        <div class="group flex">
          <div class="full-width flex flex-direction-column gap">
            <label for="phone">Telefon</label>
            <input v-model="form.phone" type="tel" id="phone" placeholder="+49 176 123 4455" />
          </div>
        </div>
      </div>

      <!-- Step 2: Job Details -->
      <div v-if="currentStep === 2" class="full-width flex flex-direction-column gap-25">
        <h2 class="font-normal weight-bold uppercase accent">Job Details</h2>

        <div class="full-width flex flex-direction-column gap">
          <label for="availability">Verfügbar ab *</label>
          <input v-model="form.availability" type="date" id="availability" required />
        </div>

        <div class="full-width flex flex-direction-column gap">
          <label for="salary">Gehaltsvorstellung *</label>
          <input v-model="form.salary" type="text" id="salary" required />
        </div>
      </div>

      <!-- Step 3: Upload Documents -->
      <div v-if="currentStep === 3" class="full-width flex flex-direction-column gap-25">
        <h2 class="font-normal weight-bold uppercase accent">Dokumente</h2>

        <label>Bitte lade uns Deinen Lebenslauf (optional Portfolio, aktuelle Zeugnisse und ein kurzes Anschreiben) hoch (insgesamt max. 20 MB).</label>

        <div class="group flex flex-direction-column-mobile gap-25">
          <div class="full-width flex flex-direction-column gap">
            <label for="resume">Lebenslauf *</label>
            <div class="upload full-width flex flex-direction-column justify-content-center align-items-center">
              <input type="file" id="resume" @change="handleFileUpload($event, 'resume')" accept=".pdf, .pptx, .xlsx, .docx, .doc, .xls, .ppt, .ods, .odt, .7z, .gz, .rar, .zip, .bmp, .gif, .jpg, .jpeg, .png, .tif, .csv, .txt, .rtf, .mp4, .3gp, .mov, .avi, .wmv" required />
              <p class="font-secondary font-small weight-regular uppercase color-text">Hier ablegen</p>
              <p class="font-secondary font-small weight-regular" v-if="form.resume">({{ bytesToMB(form.resume.size) }}MB - {{form.resume.name}})</p>
            </div>
          </div>
          <div class="full-width flex flex-direction-column gap">
            <label for="otherDocuments">Andere Dokumente</label>
            <div class="upload full-width flex flex-direction-column justify-content-center align-items-center">
              <input type="file" id="otherDocuments" @change="handleFileUpload($event, 'otherDocuments')" accept=".pdf, .pptx, .xlsx, .docx, .doc, .xls, .ppt, .ods, .odt, .7z, .gz, .rar, .zip, .bmp, .gif, .jpg, .jpeg, .png, .tif, .csv, .txt, .rtf, .mp4, .3gp, .mov, .avi, .wmv" multiple />
              <p class="font-secondary font-small weight-regular uppercase color-text">Hier ablegen</p>
              <p class="font-secondary font-small weight-regular" v-if="form.otherDocuments">
                <span v-for="(doc, i) in form.otherDocuments" :key="i">({{ bytesToMB(doc.size) }}MB - {{doc.name}})</span>
              </p>
            </div>
          </div>
        </div>

        <div class="full-width flex align-items-center gap">
          <input v-model="form.privacyAccepted" type="checkbox" id="privacy" required />
          <label for="privacy">Hiermit bestätige ich, dass ich die <a href="#">Datenschutzerklärung</a> zur Kenntnis genommen habe.*</label>
        </div>
      </div>

      <!-- Navigation Buttons -->
      <div class="full-width flex justify-content-space-between align-items-center">
        <div class="none" v-if="currentStep === 1"></div>
        <button type="button" v-if="currentStep > 1" @click="previousStep">Zurück</button>
        <button type="button" v-if="currentStep < 3" @click="nextStep">Weiter</button>
        <button type="submit" v-if="currentStep === 3" :disabled="!form.privacyAccepted">Bewerbung abschicken</button>
      </div>
    </form>
  </div>
</template>

<style scoped lang="scss">
.application-form {
  padding: 50px 0;

  @media (max-width: #{$media-phone}) {
    width: 100%;
    padding: 0;
  }

  .gap {
    gap: 4px;
  }

  input {
    width: 100%;
    padding: 10px;
    border-radius: 0px;
  }

  label, input {
    font-family: 'Acumin', sans-serif;
  }

  input[type="checkbox"] {
    width: 20px;
    height: 20px;
  }

  .upload {
    position: relative;
    width: 100%;
    aspect-ratio: 3/1;
    background: $secondary;

    input {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  button, input, .upload {
    align-self: flex-start;
    padding: 10px 25px;
    cursor: pointer;
    background: transparent;
    outline: none;
    border: 1px solid $text;
    transition: .3s ease;
    letter-spacing: 1px;
    color: $text;

    @media (max-width: #{$media-phone}) {
      padding: 10px 30px;
    }

    &:hover {
      color: $accent;
      border-color: $accent;
    }
  }
}
</style>