<script setup>
import {ref, onMounted, nextTick, onUnmounted, computed} from 'vue';

const props = defineProps({service: Object, open: Boolean, callback: Function, itemId: Number})

const desc = ref(null);
const serv = ref(null);

const maxContentHeight = ref(0);

const calculateHeight = () => {
  const descHeight = desc.value ? desc.value.scrollHeight : 0;
  const servHeight = serv.value ? serv.value.scrollHeight : 0;

  if (window.innerWidth <= 768) {
    if (servHeight <= 10) {
      maxContentHeight.value = descHeight + 50;
      return;
    }

    maxContentHeight.value = descHeight + servHeight + 100;
  } else {
    maxContentHeight.value = Math.max(descHeight, servHeight) + 60;
  }
};

onMounted(async () => {
  await nextTick();
  calculateHeight();

  window.addEventListener('resize', calculateHeight);
});

onUnmounted(() => {
  window.removeEventListener('resize', calculateHeight);
});
</script>

<template>
  <div class="accordion-item" v-on:click="callback(itemId)">
    <div class="accordion-wrapper flex flex-direction-row justify-content-space-between align-items-center">
      <h3 class="font-xl weight-bold uppercase" :class="{ 'active': open }" :data-text="service.name">{{
          service.name
        }}</h3>

      <span :class="{ 'active': open }"></span>
    </div>

    <div :class="{ 'show': open }" :style="{ height: open ? `${maxContentHeight}px` : 0}"
         class="content flex flex-direction-row flex-direction-column-mobile font-medium gap-50">
      <div class="description flex-2">

        <p class="font-small weight-regular" ref="desc">
          <MarkdownText :blok="{ text: service.content.description }"/>
        </p>
      </div>

      <div class="services flex-1">
        <ul ref="serv">
          <li v-for="(child, i) in service.children" :key="i" class="font-small weight-regular">
            {{ child.name }}
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.accordion-item {
  position: relative;
  z-index: 8;
  width: 100%;
  border-top: 2px solid #707070;
  cursor: pointer;

  &:last-child {
    border-bottom: 2px solid #707070;
  }

  .accordion-wrapper {
    padding: 25px 0 25px 0;

    h3 {
      color: transparent;
      overflow: hidden;
      line-height: 1;
      position: relative;

      &.active {
        color: transparent;
        -webkit-text-stroke: 1px white;
        transition: .3s ease-in-out;

        &:before {
          height: 0;
          transition: height .3s ease;
        }
      }

      &:before {
        font-size: inherit;
        font-weight: inherit;
        position: absolute;
        content: attr(data-text);
        height: 100%;
        left: 0;
        top: 0;
        color: white;
        overflow: hidden;
        transition: height .5s ease;
      }
    }

      span {
        position: relative;
        display: block;
        height: 50px;
        left: -25px;

        &:before {
          position: absolute;
          content: '';
          width: 7.5px;
          height: 100%;
          top: 0;
          background: white;
          transition: .3s ease;
        }

        &:after {
          position: absolute;
          content: '';
          width: 7.5px;
          height: 100%;
          top: 0;
          background: white;
          transform: rotate(90deg);
        }

        &.active:before {
          transform: rotate(90deg);
        }

        @media (max-width: #{$media-phone}) {
          height: 30px;
          left: -25px;
          margin-bottom: 8px;

          &:before {
            position: absolute;
            content: '';
            width: 3px;
            height: 100%;
            top: 0;
            background: white;
            transition: .3s ease;
          }

          &:after {
            position: absolute;
            content: '';
            width: 3px;
            height: 100%;
            top: 0;
            background: white;
            transform: rotate(90deg);
          }
        }
      }

  }

  .content {
    padding: 0;
    opacity: 0;
    height: 0;
    transition: height .3s ease, opacity .2s ease;

    &.show {
      padding: 25px 0 50px 0;
      display: flex;
      opacity: 1;
    }
  }
}
</style>