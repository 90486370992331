import {createRouter, createWebHistory} from 'vue-router'

const routes = [
  {
    path: '/:slug*',
    component: () => import('@/views/Loader.vue'),
    props: route => ({ fullSlug: route.meta.fullSlug })
  },
  {
    path: '/404',
    component: () => import('@/views/404.vue')
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach(async (to, from, next) => {
  if (to.path === '/404') {
    return next();
  }

  to.meta.fullSlug = to.fullPath.substring(1) || 'home';

  next();
});

export default router;
