<script setup>
import { ref, onMounted, onUnmounted } from 'vue';

const props = defineProps({ blok: Object })

const targetDate = new Date(props.blok.date);

const days = ref(0)
const hours = ref(0)
const minutes = ref(0)
const seconds = ref(0)

const formatNumber = (num) => num.toString().padStart(2, '0');

const updateCountdown = () => {
  const now = new Date()
  const difference = targetDate - now

  if (difference <= 0) {
    days.value = 0
    hours.value = 0
    minutes.value = 0
    seconds.value = 0
    return
  }

  days.value = formatNumber(Math.floor(difference / (1000 * 60 * 60 * 24)));
  hours.value = formatNumber(Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)));
  minutes.value = formatNumber(Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)));
  seconds.value = formatNumber(Math.floor((difference % (1000 * 60)) / 1000));
}

let interval;

onMounted(() => {
  updateCountdown();
  interval = setInterval(updateCountdown, 1000);
})

onUnmounted(() => {
  clearInterval(interval);
})
</script>

<template>
  <div class="timer flex justify-content-center align-items-center gap-50">
    <div class="group flex flex-direction-column justify-content-center align-items-center">
      <span class="font-secondary font-large weight-bold outline accent">{{ days }}</span>
      <span class="font-secondary font-small color-text">Tage</span>
    </div>

    <div class="group flex flex-direction-column justify-content-center align-items-center">
      <span class="font-secondary font-large weight-bold outline accent">{{ hours }}</span>
      <span class="font-secondary font-small color-text">Stunden</span>
    </div>

    <div class="group flex flex-direction-column justify-content-center align-items-center">
      <span class="font-secondary font-large weight-bold outline accent">{{ minutes }}</span>
      <span class="font-secondary font-small color-text">Minuten</span>
    </div>

    <div class="group flex flex-direction-column justify-content-center align-items-center">
      <span class="font-secondary font-large weight-bold outline accent">{{ seconds }}</span>
      <span class="font-secondary font-small color-text">Sekunden</span>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>