<script setup>
import { ref } from 'vue';
import axios from 'axios';

defineProps({ blok: Object });

const jobData = await axios.get('https://fourplex.marlon-schirl.me/jobs');

const groupedJobs = ref({ });

jobData.data.position.forEach((job) => {
  if (!groupedJobs.value[job.department]) {
    groupedJobs.value[job.department] = [];
  }
  groupedJobs.value[job.department].push(job);
});

let currentOpen = ref(null);

const callback = (i) => {
  currentOpen.value = currentOpen.value === i ? null : i;
}

const open = (id) => {
  return currentOpen.value === id;
}

const translation = {
  'full-time': 'Vollzeit',
  'part-time': 'Teilzeit',
  'full-or-part-time': 'Voll-/Teilzeit',
  'permanent': 'Festanstellung',
  'intern': 'Praktikum',
  'trainee': 'Trainee Stelle',
  'freelance': 'Freelance Position'
}
</script>

<template>
  <div class="accordion flex flex-direction-column gap-75">
    <div v-for="(jobs, department) in groupedJobs" :key="department" class="department flex flex-direction-column gap-25">
      <h2 class="font-xl weight-bold color-accent uppercase">{{ department }}</h2>

      <div class="job-container flex flex-direction-column">
        <div class="job" v-for="job in jobs" :key="job.id">
          <div class="accordion-item" v-on:click="callback(job.id)">
            <div class="accordion-wrapper flex flex-direction-row justify-content-space-between align-items-center">
              <div class="flex flex-direction-column gap-16 width-75" :class="{ 'flex-direction-column-reverse': open(job.id) }">
                <h3 class="font-medium weight-bold uppercase" :class="{ 'active': open(job.id) }" :data-text="job.name">{{ job.name }}</h3>

                <span class="font-normal color-accent">{{ translation[job.employmentType] }}, {{ translation[job.schedule] }} · {{ job.office }}</span>
              </div>

              <span class="plus" :class="{ 'active': open(job.id) }"></span>
            </div>

            <div :class="{ 'show': open(job.id) }" class="content flex flex-direction-column font-medium gap-50">
              <div class="flex flex-direction-column gap-16" v-for="(description, i) in job.jobDescriptions.jobDescription" :key="i">
                <h4 class="font-small color-accent">{{ description.name }}</h4>
                <p class="font-small" v-html="description.value"></p>
              </div>

              <a :href="'/bewerbung?job_id=' + job.id" class="apply-btn font-small weight-regular uppercase">Jetzt bewerben</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.accordion {
  width: 60%;

  @media (max-width: #{$media-phone}) {
    width: 100%;
  }

  .department {
    margin-bottom: 25px;

    .job-container {
      padding-left: 50px;

      @media (max-width: #{$media-phone}) {
        padding-left: 0;
      }

      .job {
        width: 100%;
        border-top: 2px solid #707070;
        cursor: pointer;

        &.no-border {
          border-top: 0;
        }

        &:last-child {
          border-bottom: 2px solid #707070;
        }

        .accordion-wrapper {
          padding: 50px 0 25px 0;

          h3 {
            color: transparent;
            overflow: hidden;
            line-height: 1;
            position: relative;

            &.active {
              color: transparent;
              -webkit-text-stroke: 1px white;
              transition: .2s ease-in-out;

              &:before {
                height: 0;
                transition: height .2s ease;
              }
            }

            &:before {
              font-size: inherit;
              font-weight: inherit;
              position: absolute;
              content: attr(data-text);
              height: 100%;
              left: 0;
              top: 0;
              color: white;
              overflow: hidden;
              transition: height .4s ease;
            }
          }

          .plus {
            position: relative;
            display: block;
            height: 50px;
            left: -25px;

            &:before {
              position: absolute;
              content: '';
              width: 7.5px;
              height: 100%;
              top: 0;
              background: white;
              transition: .3s ease;
            }

            &:after {
              position: absolute;
              content: '';
              width: 7.5px;
              height: 100%;
              top: 0;
              background: white;
              transform: rotate(90deg);
            }

            &.active:before {
              transform: rotate(90deg);
            }

            @media (max-width: #{$media-phone}) {
              height: 30px;
              left: -25px;
              margin-bottom: 8px;

              &:before {
                position: absolute;
                content: '';
                width: 3px;
                height: 100%;
                top: 0;
                background: white;
                transition: .3s ease;
              }

              &:after {
                position: absolute;
                content: '';
                width: 3px;
                height: 100%;
                top: 0;
                background: white;
                transform: rotate(90deg);
              }
            }
          }
        }

        .content {
          padding: 25px 0 50px 0;
          display: none;

          &.show {
            display: flex;
          }

          .apply-btn {
            align-self: flex-start;
            padding: 15px 40px;
            cursor: pointer;
            background: transparent;
            outline: none;
            border-radius: 10px;
            border: 2px solid $text;
            transition: .3s ease;
            letter-spacing: 2px;
            color: $text;

            @media (max-width: #{$media-phone}) {
              padding: 10px 30px;
            }

            &:hover {
             color: $accent;
              border-color: $accent;
            }
          }
        }
      }
    }
  }
}
</style>
