<script setup>
import Storyblok from '@/plugins/storyblok';
import { computed, onMounted, ref } from 'vue';

const props = defineProps({ blok: Object, name: String })

let data = await Storyblok.storyblokApiRequest(`cdn/stories/${window.location.pathname}`, {
  resolve_relations: 'services'
});

let response = await Storyblok.storyblokApiRequest('cdn/stories', {
  starts_with: 'projekte/'
});

let allProjects = response.stories.filter(item => item.name !== 'Projects');

let currentProjectSlug = window.location.pathname.split('/').slice(-2).join('/');

let currentIndex = allProjects.findIndex(project => project.full_slug === currentProjectSlug);

let nextProject = allProjects[(currentIndex + 1) % allProjects.length];

const isHovering = ref(false);
let hoverTimer = null;

const startHover = () => {
  isHovering.value = true;

  hoverTimer = setTimeout(() => {
    redirectToNext();
  }, 2000);
};

const stopHover = () => {
  isHovering.value = false;

  if (hoverTimer) {
    clearTimeout(hoverTimer);
    hoverTimer = null;
  }
};

const redirectToNext = () => {
  window.location.href = `/${nextProject.full_slug}`;
};

onMounted(() => {
  let video = document.getElementsByTagName('video')[0];

  video.controls = false;

  video.play().catch((err) => console.log(err));
});
</script>

<template>
  <section id="preview">
    <video autoplay playsinline muted loop style="width: 100%; height: 100%; object-fit: cover;" v-lazy-background="blok.image.filename">
      <source :src="blok.video?.url" type="video/mp4">
    </video>

    <div class="logo-container">
      <div class="container boxed flex justify-content-center align-items-center">
        <div class="logo flex">
          <img loading="lazy" :src="blok.logo?.filename || ''" alt="" />
        </div>
      </div>
    </div>
  </section>

  <section id="project-information">
    <div class="container boxed flex justify-content-center align-items-center">
      <div class="project-container relative flex flex-direction-row flex-direction-column-mobile gap-50">

        <div class="container flex flex-4 flex-direction-column gap-50">
          <div class="grouped">
            <h1 class="font-xl weight-bold uppercase">{{ name }}</h1>
            <h2 class="font-medium weight-light uppercase">{{ blok.subheadline }}</h2>
          </div>

          <p class="font-small font-secondary color-light">{{ blok.text }}</p>
        </div>


        <div class="container flex flex-1 flex-direction-column gap-25">
          <div class="container flex flex-direction-column">
            <p class="font-small color-light">Branche</p>

            <ul class="font-small list-style-none">
              <li>{{ blok.industry }}</li>
            </ul>
          </div>

          <div class="container flex flex-direction-column">
            <p class="font-small color-light">Leistungen</p>

            <ul class="font-small list-style-none">
              <li v-for="(item, i) in data.rels" :key="i">{{ item.name }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <StoryblokComponent
      v-for="inblok in blok.components"
      :blok="inblok"
      :key="inblok._uid"
  />

  <section id="next-project">
    <div class="background-image" v-lazy-background="nextProject.content.image.filename" />
    <div class="container flex flex-direction-column justify-content-center align-items-center gap-16" @mouseenter="startHover" @mouseleave="stopHover" @click="redirectToNext()">
      <span class="font-normal weight-light uppercase">Nächstes Projekt</span>
      <span class="font-medium weight-bold uppercase">{{ nextProject.name }}</span>
    </div>
  </section>
</template>

<style scoped lang="scss">
#preview {
  position: relative;
  height: 70vh;
  width: 100%;
  padding-bottom: 0;

  video {
    height: 100%;
    background-position: center center;
    background-size: cover;
  }

  @media only screen and (max-width: #{$media-phone}) {
    height: 40vh;
    padding-bottom: 25px;
  }

  .logo-container {
    position: absolute;
    width: 100%;
    bottom: 0;

    .container {

      .logo {
        width: 75%;

        @media only screen and (max-width: #{$media-phone}) {
          width: 100%;
        }

        img {
          width: 150px;
          margin-bottom: -7.5%;

          @media only screen and (max-width: #{$media-phone}) {
            width: 100px;
          }
        }
      }
    }
  }
}

#project-information {
  padding-bottom: 0;

  .project-container {
    width: 75%;

    @media only screen and (max-width: #{$media-phone}) {
      width: 100%;
    }
  }

  .logo {
    top: -67.5%;
  }
}

#next-project {
  position: relative;
  height: 40vh;
  width: 100%;

  .background-image {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    filter: brightness(.65);
  }

  .container {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    cursor: pointer;

    span {
      position: relative;
      z-index: 3;
    }

    &:after {
      position: absolute;
      z-index: 1;
      width: 0;
      height: 100%;
      content: '';
      background: $accent;
      transition: 2s width;

      @media only screen and (max-width: #{$media-phone}) {
        transition: none;
      }
    }

    &:hover:after {
      width: 100%;
    }
  }
}
</style>