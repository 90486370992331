<script setup>
import { onMounted, ref, onBeforeUnmount } from 'vue';

import Slider from '@/components/Slider.vue';

const props = defineProps({ blok: Object });

const updateSliderWrapperStyles = () => {
  const hero = document.getElementById('hero');
  const sliderWrapper = hero.querySelector('.slider-wrapper');

  if (!sliderWrapper || window.innerWidth <= 768) return;

  const rect = sliderWrapper.getBoundingClientRect();

  const overflowTop = rect.top + window.scrollY;
  const overflowRight = window.scrollX + window.innerWidth - rect.right;

  let slider = document.getElementById('slider');

  slider.style.height = 'calc(100% + ' + overflowTop + 'px)';
  slider.style.width = 'calc(100% + ' + overflowRight + 'px)';
};

onMounted(() => {
  updateSliderWrapperStyles();
  window.addEventListener('resize', updateSliderWrapperStyles);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSliderWrapperStyles);
});
</script>

<template>
  <section id="hero">
    <div class="container top-level flex flex-direction-column-reverse-mobile boxed align-items-center">
      <div class="text container flex flex-direction-column width-33 justify-content-center gap-16">
        <StoryblokComponent
            v-for="inblok in blok.components"
            :blok="inblok"
            :key="inblok._uid"
        />
      </div>

      <div class="slider-container flex width-66 align-items-center">
        <div class="slider-wrapper">
          <div class="slider-inner" id="slider">
            <Slider :projects="blok.projects" />
          </div>
        </div>
      </div>

      <figure v-if="blok.figure">
        <img src="@/assets/images/figures/figure_arrow_down.svg" alt="" />
      </figure>
    </div>
  </section>
</template>

<style scoped lang="scss">
#hero {
  position: relative;
  width: 100%;
  height: 1050px;
  padding-bottom: 50px;

  @media only screen and (max-width: #{$media-tablet}) {
    margin-bottom: 80px;
  }

  @media only screen and (max-width: #{$media-phone}) {
    height: 80vh;
    margin-bottom: -10%;
    padding-bottom: 0;
  }

  .top-level {
    height: 100% !important;
  }

  .container {
    position: relative;
    overflow: visible;

    .text {
      padding-top: 160px;
      z-index: 2;
    }

    @media only screen and (max-width: #{$media-phone}) {
      position: initial;
      width: 100%;
      height: 40%;

      .text {
        height: 50%;
        z-index: 0;
        padding-top: 0;
      }
    }

    figure {
      position: absolute;
      z-index: 2;
      bottom: 0;
      left: 0;
      transform: translate(-60%, 110%);
      width: 304px;
      height: 242px;

      @media (max-width: #{$media-tablet}) {
        transform: translate(-60%, 70%);
        width: 240px;
        height: 162px;
      }

      @media only screen and (max-width: #{$media-phone}) {
        display: none;
      }
    }


    .slider-container {
      height: 100%;
      margin-bottom: -100px;

      @media only screen and (max-width: #{$media-phone}) {
        height: 60%;
      }

      .slider-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        @media only screen and (max-width: #{$media-phone}) {
          position: initial;
          height: 50%;
        }

        .slider-inner {
          position: absolute;
          z-index: 1;
          bottom: 0;
          left: 0;

          @media only screen and (max-width: #{$media-phone}) {
            position: absolute;
            height: 50%;
            width: 100%;
            top: 0;
          }
        }
      }
    }
  }
}
</style>