<script setup>
import {ref, watch} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/swiper-bundle.css';

const props = defineProps({ blok: Object })

const isModalOpen = ref(false);

const currentIndex = ref(0);
const swiperInstance = ref();

const openModal = (index) => {
  currentIndex.value = index;
  isModalOpen.value = true;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const onSwiper = (swiper) => {
  swiperInstance.value = swiper
}

const nextSlide = () => {
  currentIndex.value = (currentIndex.value + 1) % props.blok.images.length;
  swiperInstance.value.slideTo(currentIndex.value);
};

const prevSlide = () => {
  currentIndex.value = (currentIndex.value - 1 + props.blok.images.length) % props.blok.images.length;
  swiperInstance.value.slideTo(currentIndex.value);
};

watch(isModalOpen, (newValue) => {
  const appElement = document.getElementById('app');
  const bodyElement = document.getElementsByTagName('body')[0];

  if (appElement) {
    appElement.style.overflow = newValue ? 'hidden' : 'initial';
    bodyElement.style.overflow = newValue ? 'hidden' : 'initial';
  }
});
</script>

<template>
  <div class="grid-gallery">
    <!-- Image Grid -->
    <div class="grid gap-50">
      <div
          class="gallery-item"
          v-for="(image, index) in blok.images"
          :key="index"
          @click="openModal(index)"
      >
        <img loading="lazy" :src="image.filename" alt="" />
      </div>
    </div>

    <!-- Modal for Swiper Gallery -->
    <div v-if="isModalOpen" class="modal" @click.self="closeModal">
      <swiper @swiper="onSwiper"
          :slides-per-view="1"
          :space-between="10"
          :loop="true"
          :initial-slide="currentIndex"
      >
        <swiper-slide v-for="(image, index) in blok.images" :key="index">
          <img loading="lazy" class="full-screen-image" :src="image.filename" alt="" />
        </swiper-slide>

        <!-- Swiper Navigation Buttons -->
        <div class="swiper-button-prev" @click="prevSlide"></div>
        <div class="swiper-button-next" @click="nextSlide"></div>
      </swiper>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.grid-gallery {
  overflow: hidden;
}

.grid {
  grid-template-columns: repeat(5, 1fr);
  overflow: hidden;

  @media (max-width: #{$media-tablet}) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: #{$media-phone}) {
    grid-template-columns: repeat(2, 1fr);
  }

  .gallery-item {
    width: 100%;
    height: auto;
    cursor: pointer;
    transition: transform 0.3s ease;

    &:hover {
      transform: scale(1.05);
    }
  }
}

/* Full-screen modal */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba($secondary, 0.85);
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  .swiper {
    width: 80%;
    height: 80%;

    .swiper-slide {
      display: flex;
      justify-content: center;
      align-items: center;

      .full-screen-image {
        height: 100%;
        width: auto;
        user-select: none;

        @media (max-width: #{$media-phone}) {
          height: auto;
          width: 70%;
        }
      }
    }
  }
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  z-index: 9999;
  color: white;
}
</style>
