<script setup>
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInstagram, faFacebook, faTiktok, faYoutube, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';

import Storyblok from '@/plugins/storyblok';

let data = await Storyblok.storyblokApiRequest('cdn/datasource_entries', {
  datasource: 'socials',
});

const socials = ref(
    data.datasource_entries.reduce((acc, social) => {
      acc[social.name] = social.value;
      return acc;
    }, {})
);
</script>

<template>
  <ul class="socials flex align-items-center list-style-none gap-25">
    <li>
      <a :href="socials.instagram">
        <FontAwesomeIcon :icon="faInstagram" />
      </a>
    </li>

    <li>
      <a :href="socials.facebook">
        <FontAwesomeIcon :icon="faFacebook" />
      </a>
    </li>

    <li>
      <a :href="socials.tiktok">
        <FontAwesomeIcon :icon="faTiktok" />
      </a>
    </li>

    <li>
      <a :href="socials.youtube">
        <FontAwesomeIcon :icon="faYoutube" />
      </a>
    </li>

    <li>
      <a :href="socials.linkedin">
        <FontAwesomeIcon :icon="faLinkedin" />
      </a>
    </li>

    <li>
      <a :href="socials.google">
        <FontAwesomeIcon :icon="faGoogle" />
      </a>
    </li>
  </ul>
</template>

<style scoped lang="scss">
ul, li, a {
  list-style: none;
  font-size: $font-normal;
  text-transform: uppercase;
  font-weight: 700;
  color: $text;

  &.socials a {
    color: $accent;
  }
}
</style>