<script setup>
import { marked } from 'marked';

const props = defineProps({ blok: Object });

let text = marked.parse(props.blok.text);
</script>

<template>
  <div class="markdown" v-html="text"></div>
</template>

<style lang="scss" scoped>

</style>
