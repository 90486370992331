<script setup>
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
import Loadingscreen from '@/views/Loadingscreen.vue';
import MobileBar from '@/components/MobileBar.vue';

import { ref } from 'vue';

const isLoading = ref(true);

const loadedCallback = () => {
  setTimeout(() => {
    isLoading.value = false;
  }, 250)
}
</script>

<template>
  <transition name="fade">
    <div v-if="isLoading">
      <Loadingscreen />
    </div>
  </transition>

  <Suspense suspensible>
    <Header/>
  </Suspense>

  <!-- Router View -->
  <Suspense>
    <template #default>
      <RouterView v-slot="{ Component }">
        <component
            :is="Component"
            :loaded="loadedCallback"
        />
      </RouterView>
    </template>
  </Suspense>

  <!-- Footer -->
  <Suspense suspensible>
    <Footer/>
  </Suspense>

  <!-- Mobile Bar -->
  <MobileBar/>
</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity .4s ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
