<script setup>
import { computed } from 'vue';

const props = defineProps({ blok: Object });

const createHtml = (blok) => {
  const element = document.createElement(blok.hierarchy);

  element.classList += blok.size + ' ';
  element.classList += blok.weight + ' ';
  element.style = blok.style;

  if (blok.font) {
    element.classList += blok.font + ' ';
  }

  if (blok.color) {
    element.classList += blok.color + ' ';
  }

  if (blok.classes) {
    element.classList += blok.classes + ' ';
  }

  if (blok.case) {
    element.classList += blok.case + ' ';
  }

  element.innerHTML = blok.text;

  return element.outerHTML;
};

const generatedHtml = computed(() => {
  return createHtml(props.blok);
});
</script>

<template>
  <div v-html="generatedHtml"></div>
</template>

<style scoped lang="scss">
div {
  display: inline-block;

  @media only screen and (max-width: #{$media-phone}) {
    display: block;
    white-space: wrap;
  }
}
</style>
