<script setup>
import { ref } from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import {Autoplay, FreeMode, Navigation, Thumbs} from 'swiper/modules';

import 'swiper/css';

import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';

const props = defineProps({ blok: Object });

const thumbsSwiper = ref(null);

const setThumbsSwiper = (swiper) => {
  thumbsSwiper.value = swiper;
};

const modules = [Autoplay, FreeMode, Navigation, Thumbs];
</script>

<template>
  <div class="gallery flex flex-direction-column gap-50">
    <swiper
        :autoplay="{ delay: 3000 }"
        :spaceBetween="10"
        :thumbs="{ swiper: thumbsSwiper }"
        :modules="modules"
        class="mySwiper2"
    >
      <swiper-slide v-for="(image, i) in blok.images" :key="i">
        <img :src="image.filename" alt="" />
      </swiper-slide>
    </swiper>

    <swiper
        @swiper="setThumbsSwiper"
        :spaceBetween="50"
        :slides-per-view="4"
        :modules="modules"
        class="mySwiper"
    >
      <swiper-slide v-for="(image, i) in blok.images" :key="i">
        <img :src="image.filename" alt="" />
      </swiper-slide>
    </swiper>
  </div>
</template>

<style scoped lang="scss">
.gallery {
  width: 100%;

  .mySwiper .swiper-slide {
    cursor: pointer;
  }
}
</style>