<script setup>
import { onBeforeUnmount, onMounted } from 'vue';

defineProps({ blok: Object });

const updateSliderWrapperStyles = () => {
  const hero = document.getElementById('hero');
  const wrapper = hero.querySelector('.image-wrapper');

  if (!wrapper || window.innerWidth <= 768) return;

  const rect = wrapper.getBoundingClientRect();

  const overflowTop = rect.top + window.scrollY;
  const overflowLeft = rect.left + window.scrollX;

  let inner = document.querySelector('.image-inner');

  inner.style.height = 'calc(100% + ' + overflowTop + 'px)';
  inner.style.width = 'calc(100% + ' + overflowLeft + 'px)';
};

onMounted(() => {
  updateSliderWrapperStyles();
  window.addEventListener('resize', updateSliderWrapperStyles);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSliderWrapperStyles);
});
</script>

<template>
  <section id="hero">
    <div class="container top-level flex flex-direction-column-reverse-mobile boxed align-items-center">
      <div class="image container flex width-60 align-items-center">
        <div class="image-wrapper">
          <div class="image-inner">
            <div class="img" v-lazy-background="blok.image.filename"></div>
          </div>
        </div>
      </div>

      <div class="text container boxed flex flex-direction-column width-40 justify-content-center gap-16">
        <div class="wrapper flex flex-direction-column align-items-flex-end gap-16">
          <StoryblokComponent
              v-for="inblok in blok.components"
              :blok="inblok"
              :key="inblok._uid"
          />
        </div>
      </div>

      <figure>
        <img src="@/assets/images/figures/figure_arrow_down.svg" alt="" />
      </figure>
    </div>
  </section>
</template>

<style scoped lang="scss">
#hero {
  position: relative;
  width: 100%;
  height: 900px;
  padding-bottom: 50px;

  @media only screen and (max-width: #{$media-phone}) {
    height: 70vh;
    margin-bottom: -17.5%;
    padding-bottom: 0;
  }

  .top-level {
    height: 100% !important;
  }

  .container {
    position: relative;
    overflow: visible;

    .text {
      position: absolute;
      right: 0;
      padding-top: 160px;
      z-index: 2;

      .wrapper {
        position: absolute;
        right: 0;
        display: flex;
        width: max-content;
        padding-right: 50px;
        margin-top: -50px;

        @media only screen and (max-width: #{$media-phone}) {
          display: initial;
          position: initial;
          right: initial;
          padding-right: initial;

          h1 {
            font-size: 45px;
          }
        }
      }
    }

    @media only screen and (max-width: #{$media-phone}) {
      position: initial;
      width: 100%;
      height: 40%;

      .text {
        height: 50%;
        z-index: 0;
        padding-top: 0;
      }
    }

    .image {
      height: 100%;
      //margin-bottom: -100px;

      @media only screen and (max-width: #{$media-phone}) {
        height: 60%;
      }

      .image-wrapper {
        position: relative;
        height: 100%;
        width: 100%;

        @media only screen and (max-width: #{$media-phone}) {
          position: initial;
          height: 50%;
        }

        .image-inner {
          position: absolute;
          z-index: 1;
          bottom: 0;
          right: 0;
          width: 100%;
          height: 100%;

          @media only screen and (max-width: #{$media-phone}) {
            position: absolute;
            height: 50%;
            width: 100%;
            top: 0;
          }

          .img {
            width: 100%;
            height: 100%;
            filter: brightness(.65);
            background-position: center center;
            background-size: cover;
          }
        }
      }
    }
  }

  figure {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    transform: translate(-65%, 30%);
    width: 304px;
    height: 242px;

    @media (max-width: #{$media-tablet}) {
      width: 240px;
      height: 162px;
    }

    @media only screen and (max-width: #{$media-phone}) {
      display: none;
    }
  }
}
</style>