<script setup async>
import { ref } from 'vue';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faInstagram, faFacebook, faTiktok, faYoutube, faLinkedin, faGoogle } from '@fortawesome/free-brands-svg-icons';

import Storyblok from '@/plugins/storyblok';

let data = await Storyblok.storyblokApiRequest('cdn/datasource_entries', {
  datasource: 'socials',
});

const socials = ref(
  data.datasource_entries.reduce((acc, social) => {
  acc[social.name] = social.value;
  return acc;
}, {})
  );
</script>

<template>
  <footer id="footer">
    <div class="container boxed flex flex-direction-column justify-content-space-between gap-125">
      <div class="flex flex-direction-column-tablet gap-75 footer">
        <ul class="nav flex align-items-center list-style-none gap-50">
          <li>
            <a href="/projekte">Projekte</a>
          </li>

          <li>
            <a href="/">Agentur</a>
          </li>

          <li>
            <a href="/jobs">Jobs</a>
          </li>

          <li>
            <a href="/kontakt">Kontakt</a>
          </li>
        </ul>

        <ul class="socials flex align-items-center list-style-none gap-25">
          <li>
            <a :href="socials.instagram">
              <FontAwesomeIcon :icon="faInstagram" />
            </a>
          </li>

          <li>
            <a :href="socials.facebook">
              <FontAwesomeIcon :icon="faFacebook" />
            </a>
          </li>

          <li>
            <a :href="socials.tiktok">
              <FontAwesomeIcon :icon="faTiktok" />
            </a>
          </li>

          <li>
            <a :href="socials.youtube">
              <FontAwesomeIcon :icon="faYoutube" />
            </a>
          </li>

          <li>
            <a :href="socials.linkedin">
              <FontAwesomeIcon :icon="faLinkedin" />
            </a>
          </li>

          <li>
            <a :href="socials.google">
              <FontAwesomeIcon :icon="faGoogle" />
            </a>
          </li>
        </ul>
      </div>

      <div class="grid">
        <div class="flex flex-direction-column gap-16">
          <span class="color-accent font-small font-secondary">Let`s talk</span>

          <a class="uppercase font-medium" href="/kontakt">Kontakt</a>
        </div>

        <div class="flex flex-direction-column gap-16">
          <span class="color-accent font-small font-secondary">Join our team</span>

          <a class="uppercase font-medium" href="/jobs">JOBS</a>
        </div>
      </div>

      <div class="bottom flex justify-content-space-between">
        <p>fourplex GmbH | Allersberger Straße 185 / C1 | 90461 Nürnberg</p>

        <span>
          <a href="/impressum">Impressum</a> |
          <a href="/datenschutz">Datenschutz</a> |
          <a href="/agb">AGB</a>
        </span>
      </div>
    </div>
  </footer>

  <footer id="footer-mobile">
    <div class="container boxed flex flex-direction-column align-items-center justify-content-center gap-75">
      <span class="imp font-secondary flex gap-8">
        <a href="/impressum">Impressum</a> |
        <a href="/datenschutz">Datenschutz</a> |
        <a href="https://fourplex.de/agb/FPX_AGB_A4_Stand-2021-09-29_2022-09-26_WEB.pdf">AGB</a>
      </span>
      <!--<div class="flex">
        <ul class="nav flex flex-direction-column list-style-none gap-16">
          <li>
            <a href="/projekte">Projekte</a>
          </li>

          <li>
            <a href="/">Agentur</a>
          </li>

          <li>
            <a href="/jobs">Jobs</a>
          </li>

          <li>
            <a href="/kontakt">Kontakt</a>
          </li>
        </ul>
      </div>

      <div class="grid gap-50">
        <div class="flex flex-direction-column gap-8">
          <span class="color-accent font-small font-secondary">Let`s talk</span>

          <a class="uppercase font-medium" href="/kontakte">Kontakt</a>
        </div>

        <div class="flex flex-direction-column gap-8">
          <span class="color-accent font-small font-secondary">Join our team</span>

          <a class="uppercase font-medium" href="/jobs">JOBS</a>
        </div>
      </div>

      <span class="imp flex flex-direction-column gap-8">
        <a href="/impressum">Impressum</a>
        <a href="/datenschutz">Datenschutz</a>
        <a href="https://fourplex.de/agb/FPX_AGB_A4_Stand-2021-09-29_2022-09-26_WEB.pdf">AGB</a>
      </span>

      <div class="bottom flex justify-content-space-between">
        <p>fourplex GmbH | Allersberger Straße 185 / C1 | 90461 Nürnberg</p>
      </div>-->
    </div>
  </footer>
</template>

<style lang="scss" scoped>
#footer {
  position: relative;
  z-index: 6;
}

@media only screen and (max-width: #{$media-phone}) {
  #footer {
    display: none;
  }

  #footer-mobile {
    display: block !important;
  }
}

#footer-mobile {
  background: $secondary;
  display: none;

  span, span a {
    list-style: none;
    font-size: $font-small;
    text-transform: none;
    font-weight: 400;
    color: rgba($text, .75);
  }

  ul, li, a {
    list-style: none;
    font-size: $font-normal;
    text-transform: uppercase;
    font-weight: 700;
    color: $text;

    &.socials a {
      color: $accent;
    }
  }

  .gap-8 {
    gap: 8px;
  }

  .bottom {
    border-top: 1px solid #FFFFFF;
    padding-top: 50px;

    p, span, span a {
      color: rgba(white, .75);
      font-size: $font-small;
      font-weight: 400;
      text-transform: none;
      text-align: center;
    }
  }
}

#footer {
  background: $secondary;

  ul, li, a {
    list-style: none;
    font-size: $font-normal;
    text-transform: uppercase;
    font-weight: 700;
    color: $text;

    &.socials a {
      color: $accent;
    }
  }

  .nav > li > a {
    position: relative;

    &:after {
      position: absolute;
      content: '';
      bottom: -16px;
      left: 0;
      height: 2px;
      width: 0;
      background-color: #ffffff;
      transition: width 0.3s ease-in-out;
    }

    &:hover:after, &.active:after {
      width: 100%;
    }
  }

  .grid {
    grid-template-columns: 1fr 1fr;
  }

  .bottom {
    border-top: 1px solid #FFFFFF;
    padding-top: 1.5rem;

    p, span, span a {
      color: rgba(white, .75);
      font-size: $font-small;
      font-weight: 400;
      text-transform: none;
    }
  }
}
</style>