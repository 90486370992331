<script setup>
import { useHead } from '@unhead/vue';

const props = defineProps({ blok: Object });

useHead({
  title: props.blok.title,
  link: [
    {
      rel: 'canonical',
      href: window.location.href
    }
  ]
});
</script>

<template>
  <StoryblokComponent
      v-for="inblok in blok.components"
      :blok="inblok"
      :key="inblok._uid"
  />
</template>