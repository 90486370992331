<script setup>
import {onMounted, ref} from 'vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css/autoplay';
import { Autoplay } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/autoplay';
import Storyblok from '@/plugins/storyblok';

const props = defineProps({ projects: Array});

let selectedProjects = await Storyblok.storyblokApiRequest('cdn/stories', {
  by_uuids: props.projects.join(',')
})

const activeSlideIndex = ref(0);

const handleSlideChange = (swiper) => {
  activeSlideIndex.value = swiper.activeIndex;
};

const updateNavigationPosition = () => {
  const swiperContainer = document.querySelector('.swiper');
  const navigation = document.querySelector('.navigation');

  if (swiperContainer && navigation) {
    const swiperLeft = swiperContainer.getBoundingClientRect().left;

    navigation.style.left = `-${swiperLeft}px`;
  }
};

onMounted(() => {
  updateNavigationPosition();

  window.addEventListener('resize', updateNavigationPosition);
});
</script>

<template>
  <swiper :modules="[Autoplay]" :autoplay="{ delay: 3000 }" @slideChange="handleSlideChange">
    <swiper-slide v-for="project in selectedProjects.stories" :key="project.id" v-lazy-background="project.content.image.filename">
    </swiper-slide>

    <div class="navigation">
      <div class="wrapper boxed flex justify-content-space-between">
        <div class="container flex width-40 hide-mobile"></div>
        <div class="container flex width-60 fw align-items-center justify-content-flex-end relative gap-50">
          <img loading="lazy" class="logo" v-if="selectedProjects.stories[activeSlideIndex].content.logo.filename" :src="selectedProjects.stories[activeSlideIndex].content.logo?.filename || ''" :alt="selectedProjects.stories[activeSlideIndex].name">

          <a
              v-for="(project, i) in selectedProjects.stories"
              :key="project.id"
              :class="{ active: i === activeSlideIndex }"
              :href="'/projekte/' + project.slug"
              class="font-normal weight-regular uppercase"
          >
            <span>Zum Projekt</span>
          </a>
        </div>
      </div>
    </div>
  </swiper>
</template>

<style scoped lang="scss">
.swiper {
  position: relative;
  width: 100%;
  height: 100%;

  .swiper-slide {
    background-position: center;
    background-size: cover;
    filter: brightness(.65);
  }

  @media (max-width: #{$media-phone}) {
    .fw {
      width: 100%;
    }
  }

  .navigation {
    position: absolute;
    z-index: 9998;
    width: 100vw;
    bottom: 20px;
    overflow: visible;

    .logo {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      max-width: 20%;
      width: auto;
      filter: brightness(.85);
    }

    a {
      pointer-events: auto;
      position: relative;
      z-index: 9999;
      padding-bottom: 10px;
      border-bottom: 2px solid $text;
      color: transparent;
      transition: color 0.2s ease, border-color 0.3s ease, width 0.3s ease;
      cursor: pointer;
      width: 50px;

      @media (max-width: #{$media-phone}) {
        width: 25px;
        gap: 12px;
      }

      span {
        white-space: nowrap;
      }

      &.active {
        width: auto;
        color: $text;
        border-color: $accent;
      }
    }
  }
}
</style>