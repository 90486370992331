export default {
    mounted(el, binding) {
        const imageUrl = binding.value;

        if (!imageUrl) return;

        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        el.style.backgroundImage = `url(${imageUrl})`;
                        el.classList.add('loaded');
                        observer.unobserve(entry.target);
                    }
                });
            },
            { threshold: 0.1 }
        );

        observer.observe(el);
    },
};