<script setup>
import { ref } from 'vue';
import Storyblok from '@/plugins/storyblok';

defineProps({ blok: Object })

let currentOpen = ref(null);

const callback = (i) => {
  currentOpen.value = currentOpen.value === i ? null : i;
}

let services = await Storyblok.storyblokApiRequest('cdn/stories', {
  starts_with: 'leistungen/'
});

let mainPages = services.stories.filter(item => item.is_startpage);

for (let i = 0; i < mainPages.length; i++) {
  mainPages[i].children = services.stories.filter(item =>
      item.full_slug.includes(mainPages[i].full_slug) && item.full_slug !== mainPages[i].full_slug
  );

  const order = mainPages[i].content.order;

  if (order) {
    mainPages[i].children = order
        .map(uuid => mainPages[i].children.find(child => child.uuid === uuid))
        .filter(child => child);
  }
}

</script>

<template>
  <div class="accordion">
    <AccordionItem v-for="(service, i) in mainPages"
                   :service="service"
                   :open="i === currentOpen"
                   :callback="callback"
                   :itemId="i"
                   :key="i" />
  </div>
</template>

<style scoped lang="scss">
.accordion {
  width: 100%;
}
</style>