import { createApp } from 'vue';

import App from './App.vue';
import router from './router';

import { createHead } from '@unhead/vue';

import { apiPlugin, StoryblokVue } from '@storyblok/vue';

import lazyBackground from '@/directives/lazyBackground';

import Page from '@/components/pages/Page.vue';
import Service from '@/components/pages/Service.vue';
import Project from '@/components/pages/Project.vue';

import Section from '@/components/Section.vue';
import Container from '@/components/Container.vue';
import Text from '@/components/Text.vue';
import Image from '@/components/Image.vue';
import Accordion from '@/components/accordion/Accordion.vue';
import AccordionItem from '@/components/accordion/AccordionItem.vue';
import Team from '@/components/Team.vue';

import Hero from '@/components/Hero.vue';
import HeroSlider from '@/components/HeroSlider.vue';
import MarkdownText from '@/components/MarkdownText.vue';
import JobApplication from '@/components/JobApplication.vue';
import GetName from '@/components/GetName.vue';
import ApplicationForm from '@/components/ApplicationForm.vue';
import OverflowImage from '@/components/OverflowImage.vue';
import Video from '@/components/Video.vue';
import IFrame from '@/components/IFrame.vue';
import Gallery from '@/components/Gallery.vue';
import GoogleMaps from '@/components/GoogleMaps.vue';
import Socials from '@/components/Socials.vue';
import Timer from '@/components/Timer.vue';
import GridGallery from '@/components/GridGallery.vue';
import Button from '@/components/Button.vue';

const app = createApp(App).use(router);

app.use(StoryblokVue, {
    accessToken: 'patgcT3YajYK9C1aaBkJ5Qtt',
    use: [apiPlugin]
})

app.directive('lazy-background', lazyBackground);

const head = createHead();

app.use(head);

app.component('Page', Page);
app.component('Service', Service);
app.component('Project', Project);

app.component('Section', Section);
app.component('Container', Container);
app.component('Text', Text);
app.component('Button', Button);
app.component('MarkdownText', MarkdownText);
app.component('Image', Image);
app.component('OverflowImage', OverflowImage);
app.component('Accordion', Accordion);
app.component('AccordionItem', AccordionItem);
app.component('Team', Team);
app.component('JobApplication', JobApplication);
app.component('ApplicationForm', ApplicationForm);
app.component('GetName', GetName);
app.component('Video', Video);
app.component('iFrame', IFrame);
app.component('Gallery', Gallery);
app.component('GridGallery', GridGallery);
app.component('GoogleMaps', GoogleMaps);
app.component('Socials', Socials);
app.component('Timer', Timer);

app.component('Hero', Hero);
app.component('HeroSlider', HeroSlider);

app.mount('#app');