<script setup>
import Storyblok from '@/plugins/storyblok';

defineProps({ blok: Object });

let data = await Storyblok.storyblokApiRequest('cdn/stories/home', {
  resolve_relations: 'team.employees'
});

const orginialLength = data.rels.length + 1;

data.rels.sort(() => Math.random() - 0.5);

const randomIndex = Math.floor(Math.random() * (data.rels.length + 1));

data.rels.splice(randomIndex, 0, { isYou: true });
</script>


<template>
  <div class="team-container grid" :style="{ gridTemplateColumns: `repeat(${[5, 4].find(d => orginialLength % d === 0) || 5}, 1fr)` }">
    <div
        v-for="(member, i) in data.rels"
        :key="i"
        class="member"
        :class="{ you: member.isYou }">

      <template v-if="member.isYou">
        <a href="/jobs" class="full-height full-width flex justify-content-center align-items-center">
          <h4 class="font-large weight-bold">DU?</h4>
        </a>
      </template>

      <template v-else>
        <img loading="lazy" :src="member.content.image.filename" :alt="member.name ||''">
        <div class="information flex flex-direction-column justify-content-space-between align-items-flex-start">
          <div class="group flex flex-direction-column">
            <span class="font-secondary weight-light color-text">{{ member.content.job }}</span>
            <span class="font-secondary weight-bold color-text">{{ member.content.first_name }} {{ member.content.last_name }}</span>
          </div>
          <span class="font-secondary color-text">{{ member.content.email }}</span>
        </div>
      </template>
    </div>
  </div>
</template>


<style scoped lang="scss">
.team-container {
  grid-template-columns: repeat(5, 1fr);
  border-image-width: 0;

  @media only screen and (max-width: #{$media-phone}) {
    grid-template-columns: repeat(3, 1fr) !important;
  }
}

.member {
  position: relative;
  text-align: center;

  &.you {
    background: $accent;
    display: flex;
    justify-content: center;
    align-items: center;

    h4 {
      transition: .2s ease;
    }

    &:hover h4 {
      transform: scale(1.1);
      letter-spacing: 4px;
    }
  }

  .information {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba($accent, 0.75);
    opacity: 0;
    transition: .2s ease;
    padding: 25px;
    text-align: left;
    font-size: 14px;

    @media only screen and (max-width: #{$media-phone}) {
      display: none;
    }

    @media only screen and (max-width: #{$media-tablet}) {
      padding: 10px;
      font-size: 12px;
    }

    &:hover {
      opacity: 1;
    }

    .group {
      gap: 8px;
    }
  }
}

img {
  width: 100%;
  height: 100%;
}
</style>