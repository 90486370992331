<script setup>
import { ref, computed, onBeforeUnmount, onMounted, nextTick } from 'vue';

const props = defineProps({ blok: Object });

const dynamicClasses = computed(() => {
  const { layout, flex, hide_mobile, hide_tablet, flex_direction, justify_content, align_items, mobile_flex_direction, mobile_justify_content, mobile_align_items, gap, additional_classes, background } = props.blok;

  let hm, ht = '';

  if (hide_mobile) {
    hm = 'hide-mobile';
  }

  if (hide_tablet) {
    ht = 'hide-tablet';
  }

  return [
    'container',
    'flex',
    ht,
    hm,
    layout || '',
    flex || '',
    flex_direction || '',
    mobile_flex_direction || '',
    justify_content || '',
    mobile_justify_content || '',
    align_items || '',
    mobile_align_items || '',
    gap || '',
    additional_classes || '',
    background || ''
  ].filter(Boolean).join(' ');
});

const wrapperRef = ref(null);
const innerRef = ref(null);
const containerRef = ref(null);

if (props.blok.overflow) {
  const updateSliderWrapperStyles = () => {
    if (!containerRef.value) return;

    const parent = containerRef.value.parentNode;

    if (!parent) return;

    const parentHeight = parent.offsetHeight;

    if (!parentHeight) return;

    containerRef.value.style.height = `${parentHeight}px`;

    if (!wrapperRef.value || window.innerWidth <= 768) return;

    const rect = wrapperRef.value.getBoundingClientRect();

    const overflowSide = rect.left + window.scrollX;

    innerRef.value.style.height = 'calc(110% + ' + overflowSide + 'px)';
    innerRef.value.style.width = 'calc(110% + ' + overflowSide + 'px)';
  };

  onMounted(() => {
    updateSliderWrapperStyles();

    window.addEventListener('resize', updateSliderWrapperStyles);
  });

  onBeforeUnmount(() => {
    window.removeEventListener('resize', updateSliderWrapperStyles);
  });
}
</script>

<template>
  <div :class="dynamicClasses" :style="{ marginBottom: blok.margin_bottom }" v-if="!blok.overflow">
    <StoryblokComponent
        v-for="inblok in blok.components"
        :blok="inblok"
        :key="inblok._uid"
    />
  </div>
  <div v-else :class="dynamicClasses" ref="containerRef" class="width-50" :style="{ marginBottom: blok.margin_bottom }">
    <div class="wrapper" ref="wrapperRef">
      <div class="inner" ref="innerRef">
        <StoryblokComponent
            v-for="inblok in blok.components"
            :blok="inblok"
            :key="inblok._uid"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.container {
  position: relative;
  overflow: visible;

  .wrapper {
    position: relative;
    height: 100%;
    width: 100%;

    .inner {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>